@import "src/scss/module";

.ServicesNonSlider {
  .ServicesGrid {
    display: flex;
    gap: 10px;
    margin: 0 10px;

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
      justify-content: center;

      :global {
        .card {
          flex-basis: 290px;
        }
      }
    }
  }

  :global {
    .card {
      width: 20%;
      height: auto;

      .card-title {
        padding: 20px;
        border-bottom: 3px solid $white;
      }

      .card-text {
        padding: 10px 20px;
      }

      &:nth-child(1) {
        .card-title {
          background-color: #1f305a;
        }
      }
      &:nth-child(2) {
        .card-title {
          background-color: #0089f9;
        }
      }
      &:nth-child(3) {
        .card-title {
          background-color: #00d5fc;
        }
      }
      &:nth-child(4) {
        .card-title {
          background-color: #7ae074;
        }
      }
      &:nth-child(5) {
        .card-title {
          background-color: #89e894;
        }
      }
    }

    .card-body {
      padding: 0 !important;
    }

    .swiper-wrapper {
      padding-left: 20px;
    }

    .swiper-slide {
      height: auto;
    }
  }
}
